<template>
  <!-- 招标公告 -->
  <div class="mainBodyA">
    <div class="noticetit marginT40">招标公告</div>
    <ul class="noticecon marginT40 clear">
      <li class="clear" v-for="(item, index) in dataList" :key="index">
        <router-link
          class="nlink"
          :to="
            '/notice-details?type=Bidding&id=' +
            item.tenderId +
            '&index=' +
            (index - 0 + 1 + (pageNo - 1) * pageSize)
          "
        >
          <div class="culList_tit fl textSl1 fontsize18">
            {{
              item.title + (item.itemNo ? "(项目编号" + item.itemNo + ")" : "")
            }}
          </div>
          <div class="newlist_con_timg fr">
            <!-- <img src="../../assets/images/index/line1.png" /> -->
          </div>
          <div class="culList_time fontsize16 fr">
            发布时间：{{ onConversion(item.releaseTime) }}
          </div>
        </router-link>
      </li>
    </ul>
    <!-- 分页 -->
    <div class="pagecon">
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="pageSize"
        :total="total"
        @size-change="getPage"
        @current-change="getPage"
      >
      </el-pagination>
    </div>
    <!-- <Pagination
            class="page"
            :total-count="total"
            :limit="pageSize"
            :get-by-page="getPage"
        /> -->
    <!-- <ul class="page">
            <li>首页</li>
            <li class="pre">《</li>
            <li class="pagenum">1</li>
            <li class="next">></li>
            <li>末页</li>
        </ul> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataList: [],
      total: 1, // 总数
      pageNo: 1, // 当前页
      pageSize: 5, // 每页显示条数
    };
  },
  mounted() {
    this.getreportList();
  },
  methods: {
    // 时间转换
    onConversion(date) {
      // console.log(date);
      var nDate = new Date(date.replace(new RegExp(/-/gm), "/"));
      // let nDate = new Date(date),
      var y = nDate.getFullYear(),
        m = ("0" + (nDate.getMonth() + 1)).slice(-2),
        d = ("0" + nDate.getDate()).slice(-2);
      // console.log(date)
      return y + "-" + m + "-" + d;
    },
    async getreportList() {
      let res = await this.$request.getreportList({
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      });
      this.dataList = res.rows;
      this.total = res.total;
      // console.log(res);
    },
    getPage(page) {
      // 获取第 page 页数据
      // console.log(page);
      this.pageNo = page;
      this.getreportList();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/notice.css";

.nlink {
  display: block;
  height: 3.13vw;
  cursor: pointer;
}
#app .culList_tit {
  min-height: 1.04vw;
  padding: 0;
}

@media (min-width: 1920px) {
  .nlink {
    display: block;
    height: 60px;
    cursor: pointer;
  }
  #app .culList_tit {
    min-height: 20px;
    padding: 0;
  }
}

.pagecon {
  text-align: center;
  padding: 20px 0;
}
.pagecon /deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #f3ca00;
  color: #fff;
}
.pagecon /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #f3ca00;
}
</style>
