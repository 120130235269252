<template>
  <div class="contain">
    <Banner
      :banner_img="resBanners"
      banner_en="NOTICE OF INVITATION FOR BIDS"
      banner_cn="招标公告"
    ></Banner>
    <!-- <div class="pnav clear">
      <div class="on">招标公告</div>
      <div>中标公示</div>
    </div> -->
    <div class="pnav clear">
      <div
        :class="[item.key === componentTag.key ? 'on' : '']"
        @click="OnChangeCont(item)"
        v-for="item in pnavList"
        :key="item.key"
      >
        <router-link class="nuxtLink" :to="'/notice?key=' + item.key">
          {{ item.text }}
        </router-link>
      </div>
    </div>
    <!-- 核心模块 -->
    <transition mode="out-in">
      <component class="wow fadeInUp animated" :is="componentTag.key"></component>
    </transition>
  </div>
</template>
<script>
import Banner from "@/components/common/Banner.vue";
import Bidding from "@/components/notice/Bidding";
import WinTheBid from "@/components/notice/WinTheBid";
export default {
  data() {
    return {
      componentTag: "Bidding",
      resBanners: [],
      pnavList: [
        {
          index: 0,
          text: "招标公告",
          key: "Bidding",
        },
        {
          index: 1,
          text: "中标公示",
          key: "WinTheBid",
        },
      ],
    };
  },
  mounted() {
    this.getBanners();
    new this.$wow.WOW({
      live: false,
      offset: 0,
    }).init();
  },
  methods: {
    OnChangeCont(data) {
      this.componentTag = data;
    },
    // 获取banner
    async getBanners() {
      let res = await this.$request.getImageAdvertisingList({
        advertisingPosition: "5",
      });
      if (res.code === 200) {
        this.resBanners = [];
        this.resBanners = res.rows;
      }
    },
  },
  components: {
    Bidding,
    WinTheBid,
    Banner,
  },
  watch: {
    // 监听路由发生改变
    $route: {
      handler(newVal, oldval) {
        if (newVal != oldval && newVal.query.key) {
          let resList = this.pnavList.filter((item) => {
            // console.log(newVal.query.key);
            // console.log(item.key);
            return newVal.query.key === item.key;
          });
          this.componentTag = resList[0];
        }
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/css/notice.css";
.noticetit {
  width: 7.8125vw;
  padding-bottom: 0.7813vw;
  text-align: center;
  font-size: 1.0417vw;
  color: #333333;
  margin: 0 auto;
  position: relative;
  border-bottom: solid 0.0521vw #e0e0e0;
}

.noticetit::after {
  content: "";
  display: block;
  width: 3.125vw;
  height: 0.1042vw;
  background: #f3cd10;
  position: absolute;
  bottom: -0.0521vw;
  left: 50%;
  margin-left: -1.5625vw;
}
.mainBodyA {
  margin-bottom: 1.0417vw;
}
.noticecon > li {
  height: 3.125vw;
  line-height: 3.125vw;
  padding: 0 1.0417vw;
  margin-bottom: 0.7813vw;
  box-sizing: border-box;
  border: solid 0.0521vw #f3f0f0;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  .culList_tit {
    color: #333;
  }
  .culList_time {
    color: #666;
  }
}

.noticecon > li:hover {
  background-color: #f3ca00;
  .culList_tit,
  .culList_time {
    color: #fff;
  }
}

.noticecon > li::before {
  position: absolute;
  content: "";
  display: block;
  width: 0.2083vw;
  height: 2.6042vw;
  left: -0.1042vw;
  top: 0.2604vw;
  background-color: #f3ca00;
}
@media (min-width: 1920px) {
  .noticetit {
    width: 150px;
    padding-bottom: 15px;
    text-align: center;
    font-size: 20px;
    color: #333333;
    margin: 0 auto;
    position: relative;
    border-bottom: solid 1px #e0e0e0;
  }

  .noticetit::after {
    content: "";
    display: block;
    width: 60px;
    height: 2px;
    background: #f3cd10;
    position: absolute;
    bottom: -1px;
    left: 50%;
    margin-left: -30px;
  }
  .mainBodyA {
    margin-bottom: 20px;
  }
  .noticecon > li {
    height: 60px;
    line-height: 60px;
    padding: 0 20px;
    margin-bottom: 15px;
    box-sizing: border-box;
    border: solid 1px #f3f0f0;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;

    .culList_tit {
      color: #333;
    }

    .culList_time {
      color: #666;
    }
  }

  .noticecon > li:hover {
    background-color: #f3ca00;

    .culList_tit,
    .culList_time {
      color: #fff;
    }
  }

  .noticecon > li::before {
    position: absolute;
    content: "";
    display: block;
    width: 4px;
    height: 50px;
    left: -2px;
    top: 5px;
    background-color: #f3ca00;
  }
}
.pagecon {
  text-align: center;
  padding: 20px 0;
}
.pagecon /deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #f3ca00;
  color: #fff;
}
.pagecon /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #f3ca00;
}
</style>
